import * as React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

export interface HeaderProps {
  siteTitle?: string
  isSlider?: boolean
  infoSection?: JSX.Element
  slideComponent?: JSX.Element
}

export const Header = ({
  siteTitle = '',
  isSlider = false,
  infoSection = null,
  slideComponent = null,
}: HeaderProps) => (
  <header className={isSlider ? 'slide' : 'section_title'}>
    <div>
      <div id="stickyNav" className="nav_logo animated fadeInDown">
        <div className="container">
          <div className="row">
            <div className="col-md-3 logo">
              <Link to="/" title="Zurück zur Homepage">
                <img src="/img/logo.svg" alt="Logo" className="logo_img" />
              </Link>
            </div>
            <nav className="col-md-9">
              <ul id="menu" className="sf-menu">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/about/">ÜBER UNS</Link>
                </li>
                <li>
                  <Link to="/projects/">PROJEKTE</Link>
                  <ul>
                    <li>
                      <Link to="/projects/">Projekte</Link>
                    </li>
                    <li>
                      <Link to="/apps/together">Together</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact/">KONTAKT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    {infoSection && (
      <div className="container">
        <div className="row animated fadeInUp delay1">{infoSection}</div>
      </div>
    )}
    {slideComponent}
  </header>
)
