/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import {isBrowser} from './utils'
import {getAnalytics, logEvent} from 'firebase/analytics'

function Seo({description, lang, meta, title}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  useEffect(() => {
    if (isBrowser()) {
      // track page/screen view
      const analytics = getAnalytics()
      //@ts-ignore
      logEvent(analytics, 'screen_view', {
        firebase_screen: window.location.pathname,
      })
    }
  }, [])

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        type="text/css"
        media="screen"
        rel="stylesheet"
        href="/css/style.css"
      />
      <link
        type="text/css"
        media="screen"
        rel="stylesheet"
        href="/css/theme-responsive.css"
      />
      <link
        type="text/css"
        media="screen"
        rel="stylesheet"
        href="/css/skins/theme-options.css"
        className="skin_color"
      />
      <link
        type="text/css"
        media="screen"
        rel="stylesheet"
        href="/css/skins/orange/orange.css"
        className="skin_color"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
