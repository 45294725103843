import {ByteLengthQueuingStrategy} from 'node:stream/web'
import React, {useState} from 'react'
import {isBrowser} from './utils'
declare global {
  interface Window {
    applyCookieConsent: any
  }
}

const styling = {
  sticky: {
    position: 'fixed' as any,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: 'white',
    borderTop: '1px solid #333',
    width: '100%',
    margin: '0',
  },
  label: {
    paddingRight: '15px',
  },
  checkbox: {
    marginLeft: '5px',
    verticalAlign: 'center',
  },
  button: {
    marginRight: '15px',
  },
}

const __consentCookie = 'moinc'
const hasConsentCookie = () =>
  isBrowser() && document.cookie.indexOf(__consentCookie) > -1

// three fields
// [0] -> functional
// [1] -> tracking
// [2] -> marketing
export type CookieConsent = boolean[]

export const CookieBox = () => {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>([
    true,
    false,
    false,
  ])
  const [hasAccept, setHasAccept] = useState(false)

  const onSetConsent = e => {
    const id = e.target.getAttribute('data-name')
    const _cookieConsent = [...cookieConsent]
    _cookieConsent[id] = !_cookieConsent[id]
    setCookieConsent(_cookieConsent)
  }

  const handleAccept = () => {
    setPrivacyCookie([true, true, true])
  }

  const handleSave = () => {
    setPrivacyCookie(cookieConsent)
  }

  const setPrivacyCookie = (consent: CookieConsent) => {
    var validFor =
      !consent[1] && !consent[2]
        ? 1000 * 60 * 60 * 24
        : 1000 * 60 * 60 * 24 * 30
    var date = new Date(Date.now() + validFor)
    var data = JSON.stringify(consent.map(item => (item ? 1 : 0)))
    if (isBrowser()) {
      document.cookie = __consentCookie + '=' + data + '; expires=' + date
    }
    setHasAccept(true)
    window.applyCookieConsent()
  }

  if (hasConsentCookie() || hasAccept) {
    return null
  }

  return (
    <div className="row cookie-box" style={styling.sticky}>
      <div className="col-md-12">
        <div className="alert alert-block alert-error fade in">
          <h4 className="alert-heading">Datenschutz-Einstellungen</h4>
          <p>
            Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen wir
            Cookies ein. Dazu zählen Cookies für die Optimierung und den Betrieb
            der Seite. So kann beispielsweise erkannt werden, wenn Sie unsere
            Seite vom selben Gerät aus wiederholt besuchen. Wir möchten Ihnen
            die Wahl geben, welche Cookies Sie zulassen:
          </p>
          <p>
            <label style={styling.label}>
              Funktional
              <input
                style={styling.checkbox}
                type="checkbox"
                disabled
                checked={cookieConsent[0]}
              ></input>
            </label>
            <label style={styling.label}>
              Tracking
              <input
                style={styling.checkbox}
                type="checkbox"
                checked={cookieConsent[1]}
                onChange={onSetConsent}
                data-name={1}
              ></input>
            </label>
            <label>
              Marketing
              <input
                style={styling.checkbox}
                type="checkbox"
                checked={cookieConsent[2]}
                onChange={onSetConsent}
                data-name={2}
              ></input>
            </label>
          </p>
          <div className="row">
            <div className="col-md-6">
              <button
                type="button"
                className="btn btn-text"
                style={styling.button}
                onClick={handleSave}
              >
                Speichern
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAccept}
              >
                Alles Akzeptieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
