/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { CookieBox } from './cookieBox'
import { Header, HeaderProps } from './header'

export interface LayoutProps extends HeaderProps {
  children: JSX.Element | JSX.Element[]
}

const Layout = ({
  children,
  slideComponent = null,
  infoSection = null,
  isSlider = false,
}: LayoutProps) => {
  const [hidden, setHidden] = useState(true)

  const enableNewsletter = false

  useEffect(() => {
    const layoutDoneEvent = new CustomEvent('LayoutDone')
    document.dispatchEvent(layoutDoneEvent)
    setTimeout(() => {
      setHidden(false)
    }, 800)
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      id="layout"
      className="layout-wide style-light"
      style={hidden ? {visibility: 'hidden'} : {}}
    >
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        slideComponent={slideComponent}
        infoSection={infoSection}
        isSlider={isSlider}
      />
      {children}
      <CookieBox />
      <footer className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h3>Soziale Medien</h3>
              <ul className="social">
                <li className="facebook">
                  <span>
                    <i className="fa fa-facebook"></i>
                  </span>
                  <a
                    href="https://www.facebook.com/moinsen.dev"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li className="twitter">
                  <span>
                    <i className="fa fa-twitter"></i>
                  </span>
                  <a href="https://twitter.com/MoinsenDev" target="_blank">
                    Twitter
                  </a>
                </li>
                <li className="github">
                  <span>
                    <i className="fa fa-github"></i>
                  </span>
                  <a href="https://github.com/moinsen-dev" target="_blank">
                    Github
                  </a>
                </li>
                <li className="linkedin">
                  <span>
                    <i className="fa fa-linkedin"></i>
                  </span>
                  <a
                    href="https://www.linkedin.com/company/moinsen-hamburg"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </li>
                <li className="linkedin">
                  <span>
                    <i className="fa fa-cube"></i>
                  </span>
                  <a
                    href="https://pub.dev/publishers/moinsen.dev/packages"
                    target="_blank"
                  >
                    Flutter packages
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <h3>Weitere Links</h3>
              <ul>
                <li>
                  <i className="fa fa-check"></i>
                  <Link to="/privacy/">Datenschutz</Link>
                </li>
                <li>
                  <i className="fa fa-check"></i>
                  <Link to="/imprint/">Impressum</Link>
                </li>
                <li>
                  <i className="fa fa-check"></i>
                  <Link to="/projects/">Projekte</Link>
                </li>
                <li>
                  <i className="fa fa-check"></i>
                  <Link to="/about/">Über uns</Link>
                </li>
              </ul>
            </div>

            {enableNewsletter && (
              <div className="col-md-3">
                <h3>NEWSLETTER SIGN UP</h3>
                <p>Enter your e-mail and subscribe to our newsletter.</p>
                <form
                  id="newsletterForm"
                  className="newsletterForm"
                  action="php/mailchip/newsletter-subscribe.php"
                >
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <input
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      type="email"
                      required
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        name="subscribe"
                      >
                        Go!
                      </button>
                    </span>
                  </div>
                </form>
                <div id="result-newsletter"></div>
              </div>
            )}

            <div className="col-md-3">
              <h3>
                <Link to="/contact/" style={{color: 'white'}}>
                  Kontakt
                </Link>
              </h3>
              <ul className="contact_footer">
                <li>
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:business@moinsen.dev">business@moinsen.dev</a>
                </li>
                <li className="location">
                  <i className="fa fa-home"></i>
                  <Link to="/contact/">Hamburg - Germany</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="coopring">
        <p>&copy; 2023 Moinsen. Alle Rechte vorbehalten.</p>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
